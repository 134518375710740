import React from 'react'

import Layout, {Grid} from '../components/layout'
import SEO from '../components/seo'


const About = () => {
    return (
        <Layout>
            <SEO/>
            <Grid ncol='eight'>
                <h2 style={{marginTop: '20px'}}>サイトについて</h2>
                <hr/>
                <div style={{margin: '20px 0'}}>
                    index は芥川賞をはじめとする有名文学賞の受賞作品をまとめたサイトです。
                </div>
                <div>
                    本サイトに掲載する情報が正確な情報になるよう細心の注意を払っておりますが、
                    本サイトのコンテンツの妥当性や正確性等について保証するものではなく、一切の責任を負いません。
                </div>
                <table className="ui celled table">
                    <tbody>
                    <tr>
                        <td>サイト名</td>
                        <td>index</td>
                    </tr>
                    <tr>
                        <td>Mail</td>
                        <td>tramadol37.5mg@gmail.com</td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td><a href="https://twitter.com/Tramadol375">Tramadol37.5mg</a></td>
                    </tr>
                    <tr>
                        <td>Github</td>
                        <td><a href="https://github.com/tramadol-hydrochloride/">tramadol-hydrochloride</a></td>
                    </tr>
                    </tbody>
                </table>
                {/*<div>*/}
                {/*    <p style={{fontSize: '12px'}}>*/}
                {/*        Book and Prize は、Amazon.co.jpを宣伝しリンクすることによって*/}
                {/*        サイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラム、*/}
                {/*        Amazonアソシエイト・プログラムの参加者です。*/}
                {/*    </p>*/}
                {/*</div>*/}
            </Grid>
        </Layout>
    );
}

export default About;
